import { FC, ReactNode } from 'react';
import { Image, type ImageProps } from '@mantine/core';

import rewindLogo from "../../static/img/rewind-logo.png";

type RewindLogoProps = ImageProps;

export const RewindLogo: FC<RewindLogoProps> = (props): ReactNode => (
  <Image
    {...props}
    src={rewindLogo}
  />
)