import { Space } from '@mantine/core';
import { formatSearchQuery, getReferenceString, MEDPLUM_VERSION, Operator, ProfileResource } from '@medplum/core';
import { UserConfiguration } from '@medplum/fhirtypes';
import {
  AppShell,
  Loading,
  NavbarMenu,
  NotificationIcon,
  useMedplum,
  useMedplumNavigate,
  useMedplumProfile,
} from '@medplum/react';
import {
  IconBrandAsana,
  IconBuilding, IconClipboardCheck,
  IconForms,
  IconId,
  IconLock,
  IconLockAccess, IconMail, IconMessage, IconMessage2Bolt,
  IconMicroscope,
  IconPackages,
  IconReceipt,
  IconReportMedical,
  IconStar,
  IconWebhook,
} from '@tabler/icons-react';
import { FunctionComponent, Suspense } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { AppRoutes } from './AppRoutes';

import { RewindLogo } from './components/Logo';

import './App.css';

export function App(): JSX.Element {
  const medplum = useMedplum();
  const profile = useMedplumProfile();
  const config = medplum.getUserConfiguration();
  const location = useLocation();
  const [searchParams] = useSearchParams();
  const navigate = useMedplumNavigate();

  // Format a search query to get all active threads assigned to the current user
  const myThreadsQuery = formatSearchQuery({
    resourceType: 'Communication',
    filters: [
      { code: 'part-of:missing', operator: Operator.EQUALS, value: 'true' },
      { code: 'recipient', operator: Operator.EQUALS, value: (profile && getReferenceString(profile)) as string },
      { code: 'status:not', operator: Operator.EQUALS, value: 'completed' },
    ],
  });

  if (medplum.isLoading()) {
    return <Loading />;
  }

  return (
    <AppShell
      logo={<RewindLogo w={100} />}
      pathname={location.pathname}
      searchParams={searchParams}
      version={MEDPLUM_VERSION}
      menus={userConfigToMenu(config, myThreadsQuery)}
      displayAddBookmark={!!config?.id}
      notifications={
        profile && (
          <>
            <NotificationIcon
              label="Mail"
              resourceType="Communication"
              countCriteria={`recipient=${getReferenceString(profile as ProfileResource)}&status:not=completed&_summary=count`}
              subscriptionCriteria={`Communication?recipient=${getReferenceString(profile as ProfileResource)}`}
              iconComponent={<IconMail />}
              onClick={() =>
                navigate(
                  `/Communication?recipient=${getReferenceString(profile as ProfileResource)}&status:not=completed&_fields=sender,recipient,subject,status,_lastUpdated`
                )
              }
            />
            <NotificationIcon
              label="Tasks"
              resourceType="Task"
              countCriteria={`owner=${getReferenceString(profile as ProfileResource)}&status:not=completed&_summary=count`}
              subscriptionCriteria={`Task?owner=${getReferenceString(profile as ProfileResource)}`}
              iconComponent={<IconClipboardCheck />}
              onClick={() =>
                navigate(
                  `/Task?owner=${getReferenceString(profile as ProfileResource)}&status:not=completed&_fields=subject,code,description,status,_lastUpdated`
                )
              }
            />
          </>
        )
      }
    >
      <Suspense fallback={<Loading />}>
        <AppRoutes />
      </Suspense>
    </AppShell>
  );
}

function userConfigToMenu(config: UserConfiguration | undefined, myThreadsQuery: string): NavbarMenu[] {
  const result =
    config?.menu?.map((menu) => ({
      title: menu.title,
      links:
        menu.link?.map((link) => ({
          label: link.name,
          href: link.target as string,
          icon: getIcon(link.target as string),
        })) || [],
    })) || [];

  result.push({
    title: "Messaging",
    links: [
      {
        icon: <IconMessage />,
        label: 'All Threads',
        href: '/Communication?part-of:missing=true&status:not=completed',
      },
      { icon: <IconMessage2Bolt />, label: 'My Threads', href: `/Communication${myThreadsQuery}` },
    ],
  });

  result.push({
    title: 'Settings',
    links: [
      {
        label: 'Security',
        href: '/security',
        icon: <IconLock />,
      },
    ],
  });

  return result;
}

const resourceTypeToIcon: Record<string, FunctionComponent> = {
  Patient: IconStar,
  Practitioner: IconId,
  Organization: IconBuilding,
  ServiceRequest: IconReceipt,
  DiagnosticReport: IconReportMedical,
  Questionnaire: IconForms,
  admin: IconBrandAsana,
  AccessPolicy: IconLockAccess,
  Subscription: IconWebhook,
  batch: IconPackages,
  Observation: IconMicroscope,
};

function getIcon(to: string): JSX.Element | undefined {
  try {
    const resourceType = new URL(to, 'https://app.medplum.com').pathname.split('/')[1];
    if (resourceType in resourceTypeToIcon) {
      const Icon = resourceTypeToIcon[resourceType];
      return <Icon />;
    }
  } catch (e) {
    // Ignore
  }
  return <Space w={30} />;
}
